<template>
  <div class="v-stack h-stretch gap-3">
    <div class="card light border h-stretch">
      <div class="content h-stretch">
        <form class="gap-3 h-stretch">
          <div class="pane-horizontal gap-3">
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">First Name</label>
              <input v-model="firstName" type="text" />
            </div>
            <div class="v-stack h-stretch gap-3">
              <label class="text-left required">Last Name</label>
              <input v-model="lastName" type="text" />
            </div>
          </div>
          <label class="text-left required">Email</label>
          <input v-model="email" type="email" />
          <label class="text-left">Phone Number</label>
          <input v-model="phoneNumber" type="text" />
          <label class="text-left">Company</label>
          <select v-model="client">
            <option
              v-for="client in clients"
              :key="client._id"
              :value="client._id"
            >
              {{ client.name }}
            </option>
          </select>
          <div class="h-stack h-end">
            <button class="submit" @click.prevent="submit()">Add</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const generator = require("generate-password");

export default {
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      client: null,
      clients: [],
      phoneNumber: "",
      accessLevel: 1,
    };
  },
  methods: {
    ...mapActions(["addUser", "getClients"]),
    submit() {
      const password = generator.generate({
        length: 8,
        numbers: true,
      });

      this.addUser({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        password: password,
        accessLevel: this.accessLevel,
        phoneNumber: this.phoneNumber,
        client: this.client,
      })
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getClients()
      .then((clients) => {
        this.clients = clients;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>